
import { Component, Vue } from "vue-property-decorator";

/**
 * Display when the content needs the user to sign in
 */
@Component({
  name: "SharedSigninToContinue",
  components: {},
})
export default class SigninToContinue extends Vue {
  private signIn() {
    const el = document.getElementById("google-signin-button");
    if (el) {
      const child = el.children[0] as HTMLElement;
      child.click();
    }
  }
}
